<template>
  <div class="pagination">
    <el-pagination
      background
      :page-sizes="MixinPageSizes"
      :page-size="paginations.pageSize"
      :layout="paginations.layout"
      :total="pageTotal"
      :current-page="paginations.pageIndex"
      @current-change="handlePageCurrentChange"
      @size-change="handlePageSizeChange"
    ></el-pagination>
  </div>
</template>

<script>
export default {
  name: 'pagination',
  data() {
    return {
      paginations: {
        pageIndex: 1, // 当前位于哪页
        pageSize: 20, // 1页显示多少条
        layout: 'total, sizes, prev, pager, next, jumper' // 翻页属性
      }
    };
  },
  props: {
    pageTotal: Number
  },
  created() {
  },
  mounted() {
  },
  methods: {
    // 上下分页 pageIndex
    handlePageCurrentChange(page) {
      this.$emit('handleCurrentChange', page);
    },
    // 每页多少条切换 pageSize
    handlePageSizeChange(pageSize) {
      this.$emit('handleSizeChange', pageSize);
    }
  }
};
</script>

<style lang="scss">
</style>
