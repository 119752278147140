import request from "@/utils/request";

// 获取消息列表
export function getMessage(params) {
  return request({
    url: `/seller/shops/shop-notice-logs`,
    method: 'get',
    loading: false,
    params
  })
}

// 删除消息列表
export function removeMessage(ids) {
  return request({
    url: `/seller/shops/shop-notice-logs/${ids}`,
    method: 'delete',
    loading: false
  })
}

// 获取消息列表
export function editMessage(ids) {
  return request({
    url: `/seller/shops/shop-notice-logs/${ids}/read`,
    method: 'put',
    loading: false
  })
}
